import * as React from "react"
import { graphql } from "gatsby"


const ExportPage = (request) => {
    const [downloadUrl, setDownloadUrl] = React.useState([]);
    const exports = request.data.allS3Object.nodes.map((node) => {
        return node.Key.split("/")[1];
    });
    const onSubmit = (e) => {
        e.preventDefault()
        const form = e.target;
        const data = new FormData(form)
        var inputVal = data.entries().next().value[1];
        if (exports.includes(inputVal)) {
            setDownloadUrl("https://jmtr-portfolio-photos.s3.us-west-2.amazonaws.com/export/" + inputVal);
        }
    }

    return (
        <main>
            <title>Export</title>
            <form onSubmit={onSubmit}>
                <input type="text" name="export" />
            </form>
            <a href={downloadUrl} download>{downloadUrl}</a>
        </main>
    )
}

export default ExportPage;

export const pageQuery = graphql`
    query ExportQuery {
        allS3Object(filter: {Key: {glob: "export/*"}}) {
            nodes {
                Key
            }
        }
    }
`
